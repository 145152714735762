//import firebase from 'firebase'; //asi netreba
import Vue from "vue";
import VueRouter from "vue-router";
//import OrderList from "../views/OrderList.vue"; // Lazy loading
//import Order from "../views/Order.vue"; // Lazy loading
//import Console from "../views/Console.vue"; // Lazy loading
//import ConsoleOsm from "../views/ConsoleOsm.vue"; // Lazy loading
//import Dashboard from "../views/Dashboard.vue"; // Lazy loading
//import VehicleList from "../views/VehicleList.vue"; // Lazy loading
//import PlaceList from "../views/PlaceList.vue"; // Lazy loading
//import Vehicle from "../views/Vehicle.vue"; // Lazy loading
import Login from "../views/Login.vue";
//import LocalityList from "../views/LocalityList.vue"; // Lazy loading
//import PricelistList from "../views/PricelistList.vue"; // Lazy loading
//import Pricelist from "../views/Pricelist.vue"; // Lazy loading
//import DriverList from "../views/DriverList.vue"; // Lazy loading
//import DriverVehicleList from "../views/DriverVehicleList.vue"; // Lazy loading
//import VehicleDriverList from "../views/VehicleDriverList.vue"; // Lazy loading
//import DispatcherList from "../views/DispatcherList.vue"; // Lazy loading
//import PersonalSetting from "../views/PersonalSetting.vue";
//import AccountList from "../views/AccountList.vue"; // Lazy loading
//import Account from "../views/Account.vue"; // Lazy loading
//import TaxiserviceList from "../views/TaxiserviceList.vue"; // Lazy loading
//import Taxiservice from "../views/Taxiservice.vue"; // Lazy loading
//import ReportOverview from "../views/ReportOverview.vue";
//import ReportStatusMonth from "../views/ReportStatusMonth.vue";
//import ReportStatusDay from "../views/ReportStatusDay.vue";
//import ReportStatusHour from "../views/ReportStatusHour.vue";
//import ReportStatusMonthPerSource from "../views/ReportStatusMonthPerSource.vue";
//import ReportSalesDriver from "../views/ReportSalesDriver.vue";
//import ReportSalesCompany from "../views/ReportSalesCompany.vue";
//import ReportWorkshift from "../views/ReportWorkshift.vue";
//import ReportWorkshiftSummary from "../views/ReportWorkshiftSummary.vue";
//import ReportDispatcherAccessLog from "../views/ReportDispatcherAccessLog.vue";
//import MessageDispatchList from "../views/MessageDispatchList.vue";
//import FeedbackList from "../views/FeedbackList.vue";
//import Settings from "../views/Settings.vue"; // Lazy loading
//import ServicePlan from "../views/ServicePlan.vue";
//import Roadmap from "../views/Roadmap.vue"; // Lazy loading
import MainSimple from "../views/MainSimple.vue";
import Main from "../views/Main.vue";
import Error404 from "../views/Error404.vue";

import i18n from "../i18n";
import store from "../store";


Vue.use(VueRouter);

const routes = [
  {
    path: '/plain',
    component: MainSimple,
    children: [
      {
        path: "/login",
        name: "login",
        alias: ["/"],

        component: Login,
        meta: {
          isForUnauthetificated: true,
          plainLayout: true,
          authRequired: false,
        },
      },
      // {
      //   path: '*',
      //   name: '404',
      //   component: Error404,
      // },
    ]
  },
  {
    path: '/',
    component: Main,
    children: [
      {
        path: "/dashboard",
        alias: ["/"],
        name: "dashboard",
        //component: Dashboard,
        component: () => import(/* webpackChunkName: "basic" */ '../views/Dashboard.vue'), // Lazy loading
        meta: {
          title: "dashboard",
          authRequired: true,
        },
      },
      {
        path: "/order",
        name: "order",
        //component: OrderList,
        component: () => import(/* webpackChunkName: "report" */ '../views/OrderList.vue'), // Lazy loading
        meta: {
          title: "orderList",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT", "OWNER", "MANAGER"],
        },
      }, {
        path: "/order/:id",
        name: "order-detail",
        //component: Order,
        component: () => import(/* webpackChunkName: "report" */ '../views/Order.vue'), // Lazy loading
        meta: {
          title: "orderDetail",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT", "OWNER", "MANAGER", "DISPATCHER"],
        },
      },
      {
        path: "/locality",
        name: "locality",
        //component: LocalityList,
        component: () => import(/* webpackChunkName: "settings" */ '../views/LocalityList.vue'), // Lazy loading
        meta: {
          title: "localityList",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT", "OWNER", "MANAGER"],
        },
      },
      {
        path: "/pricelist",
        name: "pricelist",
        //component: PricelistList,
        component: () => import(/* webpackChunkName: "settings" */ '../views/PricelistList.vue'), // Lazy loading
        meta: {
          title: "pricelistList",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT", "OWNER", "MANAGER"],
        },
      },
      {
        path: "/pricelist/:id",
        name: "pricelist-detail",
        //component: Pricelist,
        component: () => import(/* webpackChunkName: "settings" */ '../views/Pricelist.vue'), // Lazy loading
        meta: {
          title: "priceList",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT", "OWNER", "MANAGER"],
        },
      },
      {
        path: "/console",
        name: "console",
        //component: Console,
        component: () => import(/* webpackChunkName: "basic" */ '../views/Console.vue'), // Lazy loading
        meta: {
          title: "console",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT", "OWNER", "MANAGER", "DISPATCHER"],
        },
      },
      {
        path: "/console-osm",
        name: "console-osm",
        //component: ConsoleOsm,
        component: () => import(/* webpackChunkName: "consoleosm" */ '../views/ConsoleOsm.vue'), // Lazy loading
        meta: {
          title: "consoleOsm",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT"],
        },
      },
      {
        path: "/dispatcher",
        name: "dispatcher",
        //component: DispatcherList,
        component: () => import(/* webpackChunkName: "subject" */ '../views/DispatcherList.vue'), // Lazy loading
        meta: {
          title: "dispatcherList",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT", "OWNER", "MANAGER"],
        },
      },
      {
        path: "/driver",
        name: "driver",
        //component: DriverList,
        component: () => import(/* webpackChunkName: "subject" */ '../views/DriverList.vue'), // Lazy loading
        meta: {
          title: "driverList",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT", "OWNER", "MANAGER"],
        },
      },
      {
        path: "/driver-vehicle",
        name: "driver-vehicle",
        // component: DriverVehicleList,
        component: () => import(/* webpackChunkName: "basic" */ '../views/DriverVehicleList.vue'), // Lazy loading
        meta: {
          title: "driverVehicleList",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT", "OWNER", "MANAGER", "MAINTAINER", "DISPATCHER"],
        },
      },
      {
        path: "/vehicle-driver",
        name: "vehicle-driver",
        // component: VehicleDriverList,
        component: () => import(/* webpackChunkName: "basic" */ '../views/VehicleDriverList.vue'), // Lazy loading
        meta: {
          title: "vehicleDriverList",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT", "OWNER", "MANAGER", "MAINTAINER", "DISPATCHER"],
        },
      },
      {
        path: "/vehicle-maintenance",
        name: "vehicle-maintenance",
        // component: VehicleMaintenanceList,
        component: () => import(/* webpackChunkName: "basic" */ '../views/VehicleMaintenanceList.vue'), // Lazy loading
        meta: {
          title: "vehicleMaintenanceList",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT", "OWNER", "MANAGER", "MAINTAINER", "DISPATCHER"],
        },
      },
      {
        path: "/vehicle",
        name: "vehicle",
        //component: VehicleList,
        component: () => import(/* webpackChunkName: "subject" */ '../views/VehicleList.vue'), // Lazy loading
        meta: {
          title: "vehicleList",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT", "OWNER", "MANAGER"],
        },
      },
      {
        path: "/vehicle/:id",
        name: "vehicle-detail",
        //component: Vehicle,
        component: () => import(/* webpackChunkName: "subject" */ '../views/Vehicle.vue'), // Lazy loading
        meta: {
          title: "vehicleDetail",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT", "OWNER", "MANAGER"],
        },
      },
      {
        path: "/place",
        name: "place",
        //component: PlaceList,
        component: () => import(/* webpackChunkName: "general" */ '../views/PlaceList.vue'), // Lazy loading
        meta: {
          title: "placeList",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT", "OWNER", "MANAGER", "DISPATCHER"],
        },
      },
      {
        path: "/taxiservice",
        name: "taxiservice",
        //component: TaxiserviceList,
        component: () => import(/* webpackChunkName: "settings" */ '../views/TaxiserviceList.vue'), // Lazy loading
        meta: {
          title: "taxiserviceList",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT"],
        },
      },
      {
        path: "/taxiservice/:id",
        name: "taxiservice-detail",
        //component: Taxiservice,
        component: () => import(/* webpackChunkName: "settings" */ '../views/Taxiservice.vue'), // Lazy loading
        meta: {
          title: "taxiserviceDetail",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT"],
        },
      },
      {
        path: "/roadmap",
        name: "roadmap",
        //component: Roadmap,
        component: () => import(/* webpackChunkName: "general" */ '../views/Roadmap.vue'), // Lazy loading
        meta: {
          title: "roadmap",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT", "OWNER"],
        },
      },
      {
        path: "/account",
        name: "account",
        //component: AccountList,
        component: () => import(/* webpackChunkName: "subject" */ '../views/AccountList.vue'), // Lazy loading
        meta: {
          title: "accountList",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT", "OWNER", "MANAGER"],
        },
      },
      {
        path: "/account/:id",
        name: "account-detail",
        //component: Account,
        component: () => import(/* webpackChunkName: "subject" */ '../views/Account.vue'), // Lazy loading
        meta: {
          title: "accountDetail",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT", "OWNER", "MANAGER"],
        },
      },
      {
        path: "/personal-setting",
        name: "personal-setting",
        //component: PersonalSetting,
        component: () => import(/* webpackChunkName: "general" */ '../views/PersonalSetting.vue'), // Lazy loading
        meta: {
          title: "personalSetting",
          authRequired: true,
        },
      },
      {
        path: "/settings",
        name: "settings",
        //component: Settings,
        component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'), // Lazy loading
        meta: {
          title: "settings",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT", "OWNER", "MANAGER"],
        },
      },
      {
        path: "/taxiservice-status",
        name: "taxiservice-status",
        //component: Settings,
        component: () => import(/* webpackChunkName: "settings" */ '../views/TaxiserviceStatus.vue'), // Lazy loading
        meta: {
          title: "taxiserviceStatus",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT"],
        },
      },
      {
        path: "/about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "general" */ "../views/About.vue"),
        meta: {
          title: "about",
          authRequired: true,
        },
      },
      {
        path: "/report-overview",
        name: "report-overview",
        //component: ReportOverview,
        component: () => import(/* webpackChunkName: "report" */ '../views/ReportOverview.vue'), // Lazy loading
        meta: {
          title: "reportOverview",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT", "OWNER", "MANAGER"],
        },

      },
      {
        path: "/report-status-month",
        name: "report-status-month",
        //component: ReportStatusMonth,
        component: () => import(/* webpackChunkName: "report" */ '../views/ReportStatusMonth.vue'), // Lazy loading
        meta: {
          title: "reportStatusMonth",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT", "OWNER", "MANAGER"],
        },
      },
      {
        path: "/report-status-day",
        name: "report-status-day",
        //component: ReportStatusDay,
        component: () => import(/* webpackChunkName: "report" */ '../views/ReportStatusDay.vue'), // Lazy loading
        meta: {
          title: "reportStatusDay",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT", "OWNER", "MANAGER"],
        },
      },
      {
        path: "/report-status-hour",
        name: "report-status-hour",
        //component: ReportStatusHour,
        component: () => import(/* webpackChunkName: "report" */ '../views/ReportStatusHour.vue'), // Lazy loading
        meta: {
          title: "reportStatusHour",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT", "OWNER", "MANAGER"],
        },
      },
      {
        path: "/report-daily-vehicles",
        name: "report-daily-vehicles",
        //component: ReportDailyVehicles,
        component: () => import(/* webpackChunkName: "report" */ '../views/ReportDailyVehicles.vue'), // Lazy loading
        meta: {
          title: "reportDailyVehicles",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT"],
        },
      },
      {
        path: "/report-status-month-per-source",
        name: "report-status-month-per-source",
        //component: ReportStatusMonthPerSource,
        component: () => import(/* webpackChunkName: "report" */ '../views/ReportStatusMonthPerSource.vue'), // Lazy loading
        meta: {
          title: "reportStatusMonthPerSource",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT", "OWNER", "MANAGER"],
        },
      },
      {
        path: "/report-sales-driver",
        name: "report-sales-driver",
        //component: ReportSalesDriver,
        component: () => import(/* webpackChunkName: "report" */ '../views/ReportSalesDriver.vue'), // Lazy loading
        meta: {
          title: "reportSalesDriver",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT", "OWNER", "MANAGER"],
        },
      },
      {
        path: "/report-sales-company",
        name: "report-sales-company",
        //component: ReportSalesCompany,
        component: () => import(/* webpackChunkName: "report" */ '../views/ReportSalesCompany.vue'), // Lazy loading
        meta: {
          title: "reportSalesCompany",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT", "OWNER", "MANAGER"],
        },
      },
      {
        path: "/report-workshift",
        name: "report-workshift",
        //component: ReportWorkshift,
        component: () => import(/* webpackChunkName: "report" */ '../views/ReportWorkshift.vue'), // Lazy loading
        meta: {
          title: "reportWorkshift",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT", "OWNER", "MANAGER"],
        },
      },
      {
        path: "/report-workshift-summary",
        name: "report-workshift-summary",
        //component: ReportWorkshiftSummary,
        component: () => import(/* webpackChunkName: "report" */ '../views/ReportWorkshiftSummary.vue'), // Lazy loading
        meta: {
          title: "reportWorkshiftSummary",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT", "OWNER", "MANAGER"],
        },
      },
      {
        path: "/report-call-log",
        name: "report-call-log",
        //component: x,
        component: () => import(/* webpackChunkName: "report" */ '../views/ReportCallLog.vue'), // Lazy loading
        meta: {
          title: "reportCallLog",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT", "OWNER", "MANAGER"],
        },
      },
      {
        path: "/report-order-sent-log",
        name: "report-order-sent-log",
        //component: x,
        component: () => import(/* webpackChunkName: "report" */ '../views/ReportOrderSentLog.vue'), // Lazy loading
        meta: {
          title: "reportOrderSentLog",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT", "OWNER", "MANAGER"],
        },
      },
      {
        path: "/report-dispatcher-access-log",
        name: "report-dispatcher-access-log",
        //component: ReportDispatcherAccessLog,
        component: () => import(/* webpackChunkName: "report" */ '../views/ReportDispatcherAccessLog.vue'), // Lazy loading
        meta: {
          title: "reportDispatcherAccessLog",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT", "OWNER", "MANAGER"],
        },
      },
      {
        path: "/message-dispatch-list",
        name: "message-dispatch-list",
        //component: MessageDispatchList,
        component: () => import(/* webpackChunkName: "settings" */ '../views/MessageDispatchList.vue'), // Lazy loading
        meta: {
          title: "messageDispatchList",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT"],
        },
      },
      {
        path: "/feedback-list",
        name: "feedback-list",
        //component: FeedbackList,
        component: () => import(/* webpackChunkName: "settings" */ '../views/FeedbackList.vue'), // Lazy loading
        meta: {
          title: "feedbackList",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT"],
        },
      },
      {
        path: "/service-plan",
        name: "service-plan",
        //component: ServicePlan,
        component: () => import(/* webpackChunkName: "settings" */ '../views/ServicePlan.vue'), // Lazy loading
        meta: {
          title: "servicePlan",
          authRequired: true,
          roles: ["ADMIN", "SUPPORT"],
        },
      },
    ],

  },


];

const router = new VueRouter({
  mode: "history",
  //base: process.env.BASE_URL + "/en",
  routes,
});

//Vyplnanie nazvu stranky s podstrankou    !!!TREBA PREKLINUT(reloadnut) STRANKU!!!  potom to treba prerobit
router.beforeEach((to, from, next) => {
  // $emit('pagetitle',process.env.VUE_APP_TITLE + "- "+ i18n.t('page_title.console'))
  if (to.meta.title) {
    document.title = process.env.VUE_APP_TITLE + " - " + i18n.t('page_title.' + to.meta.title)
  } else {
    document.title = process.env.VUE_APP_TITLE + " - " + to.name
  }

  // next()

  //const loggedIn = localStorage.getItem('user');
  //console.log("loggedIn: " + loggedIn);

  var user = store.state.user;
  //console.log("user: " + user);
  var enabled = false;
  if (to.meta.authRequired) {
    if (user) {
      //UPRAVA PRE OVERENIE ROLI PRE CELE STRANKY
      if (to.meta.roles) {
        enabled = false;
        enabled = user.roleList.some((a) => (to.meta.roles.includes(a) ? true : false));
        if (enabled) {
          next();
        } else {
          next({ name: "dashboard" });
        }
      } else {
        next();
      }
    } else {
      //Zapamatanie si z akej stranky sme boli presmerovani na prihlasenie
      const loginpath = window.location.pathname;
      // alert('You must be logged in to see this page');
      next({
        name: 'login', query: { redirect: loginpath }
      });
    }
  } else {
    if (to.name == 'login' && user) {
      next({
        name: 'dashboard',
      });
    } else {
      next()
    }
  }
});

// router.afterEach((to, from) => {
//   Vue.nextTick( () => {
//     document.title = to.meta.title ? to.meta.title : 'default title';
//   });
// })
// router.beforeEach((to, from, next) => {

//   // use the language from the routing param or default language
//   var lang = to.params.locale;
//   console.log('fffffffffffffff ', to.params)
//   if (!lang) {
//     lang = 'en';
//     next('/'+lang+to.path);
//   }else{
//     next()
//   }
//   // set the current language for vuex-i18n. note that translation data
//   // for the language might need to be loaded first
//   //Vue.i18n.set(language);

// });

export default router;
