import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
//import * as VueGoogleMaps from "vue2-google-maps";
import * as moment from "moment/moment";
import VCalendar from "v-calendar";
import VueFlags from "@growthbunker/vueflags";
import VueI18n from "vue-i18n";
import i18n from "./i18n";
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
//import { initializeAuth, indexedDBLocalPersistence, browserLocalPersistence, signInWithRedirect } from "firebase/auth";
import JsonExcel from "vue-json-excel";
//https://www.npmjs.com/package/vue-json-excel
import VirtualList from 'vue-virtual-scroll-list';
//pridany komponent virtual scroll ale zatial nepouzivany
//https://github.com/tangbc/vue-virtual-scroll-list/blob/master/example/src/views/dynamic-size/Code.vue
import leaftlet from "leaflet";
import fetchRequest from './fetchInterceptor';
Vue.prototype.$fetchRequest = fetchRequest;

Vue.component('virtual-list', VirtualList);

Vue.component("downloadExcel", JsonExcel);

Vue.filter('formatDateTimeTechnical', function (value) {
  if (value) {
    return moment(String(value)).format('YYYY/MM/DD HH:mm')
  }
});
Vue.filter('formatDateLong', function (value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY HH:mm:ss')
  }
});
Vue.filter('formatDateShort', function (value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YY HH:mm')
  }
});
Vue.filter('formatDateTimeMini', function (value) {
  if (value) {
    return moment(String(value)).format('DD.MM.|HH:mm')
  }
});

Vue.use(VueI18n);

/*Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAdG-Vz3YTYE4F5MTALr6-rU1XbQhD-9Ig",
    libraries: "places",
  },
});*/

Vue.use(VueFlags, {
  // Specify the path of the folder where the flags are stored.
  iconPath: process.env.BASE_URL + "flags/",
});

Vue.use(VCalendar, {
  componentPrefix: "vc", // Use <vc-calendar /> instead of <v-calendar />
});

Vue.component("my-datepicker", () =>
  import("./components/MyDatetimePicker.vue")
);

Vue.component("date-range-picker", () =>
  import("./components/DateRangePicker.vue")
);

Vue.component("datetime-range-picker", () =>
  import("./components/DateTimeRangePicker.vue")
);

axios.interceptors.request.use(async config => {
  //config.withCredentials = true;
  config.headers.Authorization = `Bearer ` + await firebase.auth().currentUser.getIdToken(false)
  //pridany pokus akceptovat vsetky typy odpovedi, lebo je nikekedy problem s prihlasovanim
  config.headers.Accept = '*/*';
  //config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8;application/json'
  //config.headers.User = `Test`
  return config
}, (error) => {
  // read from local storage "firebaseData"
  /*  if(localStorage.getItem("firebaseData") == null){
      router.push({ name: "Login" });
    } else {
      //read from firebaseData
      firebase.auth().signInWithCustomToken(localStorage.getItem("firebaseData"))
      .then((userCredential) => {
        // Signed in
        var user = userCredential.user;
        // ...
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        // ..
      });
    }*/
  return Promise.reject(error)
})

var firebaseConfig = {
  apiKey: "AIzaSyA4gm3hYsmRs5hYR43_GNpy2sLyD6VnP-I",
  authDomain: "taxximo2.firebaseapp.com",
  databaseURL: "https://taxximo2.firebaseio.com",
  projectId: "taxximo2",
  storageBucket: "taxximo2.appspot.com",
  messagingSenderId: "704431070251",
  appId: "1:704431070251:web:bb09089cb2dfcf39495e6e",
  measurementId: "G-HXNJRZHQWN"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
/*const firebaseApp = firebase.initializeApp(firebaseConfig);
const auth = initializeAuth(firebaseApp, {
  persistence: [indexedDBLocalPersistence, browserLocalPersistence],
});*/
firebase.analytics();

Vue.config.productionTip = false;

new Vue({
  //firebase,
  moment,
  store,
  axios,
  vuetify,
  i18n,
  router,
  leaftlet,
  render: (h) => h(App),
}).$mount("#app");
