var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-app-bar',{attrs:{"app":"","clipped-left":"","color":"primary"}},[(this.$vuetify.theme.dark)?_c('img',{attrs:{"src":require('../assets/taxximo_logo_white.svg'),"height":"40"}}):_c('img',{attrs:{"src":require('../assets/taxximo_logo_black.svg'),"height":"40"}}),(
        _vm.releaseVersion && _vm.releaseVersion != '' && _vm.releaseVersion != 'prod'
      )?_c('v-chip',{staticClass:"ml-2",attrs:{"color":_vm.releaseVersion == 'local'
          ? 'red'
          : _vm.releaseVersion == 'alpha'
          ? 'purple'
          : _vm.releaseVersion == 'beta'
          ? 'secondary'
          : 'primary',"text-color":"white","small":""}},[_vm._v(_vm._s(_vm.releaseVersion))]):_vm._e(),_c('v-toolbar-items',[_c('v-spacer')],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.isDark = !_vm.isDark}}},[(!_vm.isDark)?_c('v-icon',{attrs:{"title":"Prepnúť na nočný režim (tmavé zobrazenie)"}},[_vm._v("fa-moon")]):_vm._e(),(_vm.isDark)?_c('v-icon',{attrs:{"title":"Prepnúť na denný režim (svetlé zobrazenie)"}},[_vm._v("fa-sun")]):_vm._e()],1),_c('v-menu',{attrs:{"left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('gb-flag',{attrs:{"code":_vm.getLocale(),"size":"small"}})],1)]}}])},[_c('v-list',{attrs:{"flat":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.changeLocale('sk')}}},[_c('v-list-item-icon',[_c('gb-flag',{attrs:{"code":"sk","size":"small"}})],1),_c('v-list-item-title',[_c('small',[_vm._v(_vm._s(_vm.$t("Main.language.SK")))])])],1),_c('v-list-item',{on:{"click":function($event){return _vm.changeLocale('en')}}},[_c('v-list-item-icon',[_c('gb-flag',{attrs:{"code":"us","size":"small"}})],1),_c('v-list-item-title',[_c('small',[_vm._v(_vm._s(_vm.$t("Main.language.EN")))])])],1)],1)],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('router-view')],1),_c('v-snackbar',{attrs:{"timeout":_vm.snackbar.timeout,"right":"","value":"true","color":_vm.snackbar.color},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }