<template>
  <div>
    <v-app-bar app clipped-left color="primary">
      <!--<v-toolbar-title>Taxximo</v-toolbar-title>-->
      <img
        v-if="this.$vuetify.theme.dark"
        :src="require('../assets/taxximo_logo_white.svg')"
        height="40"
      />
      <img
        v-else
        :src="require('../assets/taxximo_logo_black.svg')"
        height="40"
      /><!-- zobrazit maly text o aku verziu (releaseVersion) sa jedna, napriklad beta -->
      <v-chip
        v-if="
          releaseVersion && releaseVersion != '' && releaseVersion != 'prod'
        "
        :color="
          releaseVersion == 'local'
            ? 'red'
            : releaseVersion == 'alpha'
            ? 'purple'
            : releaseVersion == 'beta'
            ? 'secondary'
            : 'primary'
        "
        text-color="white"
        class="ml-2"
        small
        >{{ releaseVersion }}</v-chip
      >
      <v-toolbar-items>
        <v-spacer></v-spacer>
      </v-toolbar-items>
      <v-spacer></v-spacer>

      <v-btn icon @click="isDark = !isDark">
        <v-icon v-if="!isDark" title="Prepnúť na nočný režim (tmavé zobrazenie)">fa-moon</v-icon>
        <v-icon v-if="isDark" title="Prepnúť na denný režim (svetlé zobrazenie)">fa-sun</v-icon>
      </v-btn>

      <v-menu left bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <gb-flag :code="getLocale()" size="small" />
          </v-btn>
        </template>
        <v-list flat>
          <v-list-item @click="changeLocale('sk')">
            <v-list-item-icon>
              <gb-flag code="sk" size="small" />
            </v-list-item-icon>
            <v-list-item-title
              ><small>{{ $t("Main.language.SK") }}</small></v-list-item-title
            >
          </v-list-item>
          <v-list-item @click="changeLocale('en')">
            <v-list-item-icon>
              <gb-flag code="us" size="small" />
            </v-list-item-icon>
            <v-list-item-title
              ><small>{{ $t("Main.language.EN") }}</small></v-list-item-title
            >
          </v-list-item>
          <!--<v-list-item @click="changeLocale('cs')">
            <v-list-item-icon>
              <gb-flag code="cz" size="small" />
            </v-list-item-icon>
            <v-list-item-title><small>{{ $t("Main.language.CS") }}</small></v-list-item-title>
          </v-list-item>-->
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-container fluid>
      <router-view></router-view>
    </v-container>
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data: () => ({
    isDark: false,

    releaseVersion: "",

    snackbar: {
      show: false,
      message: "OK",
      color: "success",
      timeout: 2000,
    },
  }),
  watch: {
    isDark: function (val) {
      this.$vuetify.theme.dark = val;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    },
  },
  methods: {
    changeLocale(locale) {
      this.$store.commit("setAppLocale", locale);
      this.$i18n.locale = locale;
      // this.$router.go()  //refreshne aktualnu page
    },

    getLocale() {
      var locale = this.$store.state.appLocale;
      if (locale == "en") {
        locale = "us";
      } else if (locale == "cs") {
        locale = "cz";
      }
      return locale;
    },

    getVersion() {
      var version = window.location.href;
      //get domain name of 3th level (after 2 slashes and before fist "." or "/")
      version = version.substring(version.indexOf("//") + 2);
      if (version.indexOf(".") > -1) {
        version = version.substring(0, version.indexOf("."));
      } else if (version.indexOf("/") > -1) {
        version = version.substring(0, version.indexOf("/"));
      }
      //if version string begins with localhost, then set version to "local"
      if (version.substring(0, 9) == "localhost") {
        version = "local";
      }
      if (version.substring(0, 3) == "127") {
        version = "local";
      }
      if (version == "alpha" || version == "alfa") {
        version = "alpha";
      }
      if (version == "beta" || version == "glampoll" || version == "vue") {
        version = "beta";
      }
      //console.log("version: " + version);
      //pri produkcnej verzii nic nezobrazovat
      if (
        version == "dis" ||
        version == "dispatch" ||
        version == "dispatching"
      ) {
        version = "";
      }
      this.releaseVersion = version;
    },
  },
  created() {
    this.getVersion();
  },
  mounted() {
    //Nacitanie ulozenej temy
    const theme = localStorage.getItem("dark_theme");
    if (theme) {
      if (theme == "true") {
        this.isDark = true;
        this.$vuetify.theme.dark = true;
      } else {
        this.isDark = false;
        this.$vuetify.theme.dark = false;
      }
    }
  },
};
</script>
