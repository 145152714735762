import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";
import "@fortawesome/fontawesome-free/css/all.css"; // Ensure you are using css-loader
import i18n from "../i18n";

//import sk from 'vuetify/lib/locale/sk'
//mport { en, sk } from 'vuetify/lib/locale'


//import VueI18n from 'vue-i18n'

Vue.use(Vuetify);
//ue.use(VueI18n)

// const messages = {
//   en: {
//     $vuetify: {
//       ...en,
//       test: "EEEEEEEEEEENNNNNNNNNNNN"
//     },

//   },
//   sk: {
//     $vuetify: {
//       ...sk,
//       test: "EEEEEEEEEEENNNNNNNNNNNN"
//     },
//   },
// }

// const i18n = new VueI18n({
//   locale: 'sk', // set locale
//   fallbackLocale: "en",
//   messages, // set locale messages
// })

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  // lang: {
  //   t: (key, ...params) => i18n.t(key, params),
  // },
  // lang: {
  //   locales: { sk, en },
  //   current: "en",
  // },
  icons: {
    iconfont: "mdi",
  },
  theme: {
    themes: {
      light: {
        primary: '#FFCC00', 
        secondary: colors.blue.darken2, // #1976D2
        accent: colors.indigo.base, // #3F51B5
      },
      dark: {
        primary: colors.blue.darken2, // #1976D2
      },
    },
  },
});
