import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    appLocale: localStorage.getItem("appLocale") || process.env.VUE_APP_I18N_LOCALE || 'sk',
    // If there is a object "user" in the localStorage we get it, otherwise we set it to null
    //user: localStorage.getItem("user") || null, //user: null, //dat na null, ak nechceme aby sa pri refreshi stranky zapamatoval user
    user: null,
    module: null,
  },

  getters: {
    getAppLocale: (state) => state.appLocale,
    getUser: (state) => state.user,
    getModule: (state) => state.module,
  },

  mutations: {
    setAppLocale(state, locale) {
      state.appLocale = locale;
      localStorage.setItem("appLocale", locale); // Whenever we change the appLanguage we save it to the localStorage
    },
    setUser(state, user) {
      state.user = user;
      //set object "user" to local storage
      //localStorage.setItem("user", JSON.stringify(user)); //vymazat ak nechceme aby sa pri refreshi stranky zapamatoval user
    },
    setModule(state, module) {
      state.module = module;
    }
  },

  actions: {},
  modules: {},
});
