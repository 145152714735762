import cs from "vuetify/lib/locale/cs";

export default {

  $vuetify: {
    ...cs,
  },

  menu: {
    console: "Konzole",
    order: "Objednávky",
    pricelist: "Ceníky",
    vehicle: "Vozidla",
    taxiservice: "Taxislužby",
    about: "O aplikaci",
    roadmap: "Roadmap",
    driver: "Vodiči",
    dispatcher: "Dispečeri",
    locality:"Lokality",
    place:"Places",
  },

  page_title: {
    orderList: "Seznam objednávek",
    localityList:"Lokality",
    pricelistList: "Správa ceníků",
    priceList: "Cenník",
    console: "Dispečerská konzole",
    vehicleList: "Správa vozidel",
    vehicleDetail: "Detail vozidla",
    taxiserviceList: "Správa taxislužeb",
    about: "O aplikaci"
  },

  orderStatus: {
    PREREGISTERED: "Předregistrovaná",
    TRANSFERED: "Přesměrovaná",
    NEW: "Nová",
    PLANNED: "Plánovaná",
    PENDING: "Čakajúca",
    ACCEPTED: "Akceptovaná",
    WAITING: "Na mieste",
    IN_PROGRESS: "Vo vozidle",
    FINISHED: "Ukončená",
    CHARGED: "Naúčtovaná",
    SETTLED: "Zúčtovaná",
    ARCHIVED: "Archivovaná",
    CANCELLED_PREREGISTERED: "Odregistrovaná",
    CANCELLED_NEW: "Zneplatnená",
    CANCELLED_PLANNED: "Nerealizovaná",
    CANCELLED_PENDING: "Odvolaná",
    CANCELLED_ACCEPTED: "Odmietnutá",
    CANCELLED_WAITING: "Zrušená",
    CANCELLED_IN_PROGRESS: "Zamietnutá",
    CANCELLED_FINISHED: "Nedokončená",
    CANCELLED_CHARGED: "Nenaúčtovaná",
    CANCELLED_SETTLED: "Nezaplatená",
  },

  orderStatus_tooltip: {
    PREREGISTERED: "Predregistrovaná objednávka",
    TRANSFERED: "Presmerovaná objednávka",
    NEW: "Nová objednávka",
    PLANNED: "Plánovaná objednávka",
    PENDING: "Čakajúca objednávka",
    ACCEPTED: "Akceptovaná objednávka",
    WAITING: "Vodič čaká na mieste vyzdvihnutia",
    IN_PROGRESS: "Vodič prepravuje zákazníka",
    FINISHED: "Ukončená objednávka",
    CHARGED: "Naúčtovaná objednávka",
    SETTLED: "Zúčtovaná objednávka",
    ARCHIVED: "Archivovaná objednávka",
    CANCELLED_PREREGISTERED: "Zrušená Pregistrovaná objednávka",
    CANCELLED_NEW: "Zrušená Nová objednávka",
    CANCELLED_PLANNED: "Zrušená Plánovaná objednávka",
    CANCELLED_PENDING: "Zrušená Čakajúca objednávka",
    CANCELLED_ACCEPTED: "Zrušená Akceptovaná objednávka",
    CANCELLED_WAITING: "Zrušená objednávka Na mieste",
    CANCELLED_IN_PROGRESS: "Zrušená objednávka Vo vozidle",
    CANCELLED_FINISHED: "Zrušená Ukončená objednávka",
    CANCELLED_CHARGED: "Zrušená Naúčtovaná objednávka",
    CANCELLED_SETTLED: "Zrušená Zúčtovaná objednávka",
  },

  orderType: {
    INSTANT: "Okamžitá",
    TIME: "Časovka",
    STREET: "Z ulice",
    DIRECT: "Priama",
    REPEATED: "Opakovaná",
    AIRPORT: "Letisková",
  },
  orderType_tooltip: {
    INSTANT: "Objednávka na okamžitú realizáciu",
    TIME: "Objednávka na presný čas",
    STREET: "Objednávka z ulice",
    DIRECT: "Priama objednávka",
    REPEATED: "Opakovaná objednávka",
    AIRPORT: "Objednávka s vyzdvihnutím na letisku",
  },

  OrderListView: {
    page_title: "Zoznam objednávok",
    Id: "Č. objednávky",
    Id_tooltip: 'Technické číslo objednávky {msg}',
    Status: "Stav",
    Type: "Typ",
    Note: "Poznámka",
    CreatedAt: "Vytvorené",
    DriverId: "Č. vodiča",
    EstimatedPrice: "Predpokladaná cena",
    FinalPrice: "Finálna cena",
  },
  PricelistEnums: {
    Status: {
      ACTIVE: "Aktívny",
      INACTIVE: "Neaktívny",
      HIDDEN: "Skrytý",
    },
    
    PriceDriveType: {
      FIXED: "FIXED",
      DISTANCE: "DISTANCE",
    },

    PriceStartFareType: {
      FIXED: "FIXED",
      FROM_STAND: "FROM_STAND",
      TO_STAND: "TO_STAND",
      BETWEEN_STAND: "BETWEEN_STAND",
    },
    Service: {
      PERSONAL: "Osobní přeprava",
      LIMO: "Limuzínová přeprava",
      CARGO: "Nákladní přeprava",
      DRINK: "DRINK",
      IMMOBILE: "IMMOBILE",
      COURIER: "COURIER",
      DELIVERY: "DELIVERY",
      AIRPORT: "AIRPORT",
      SOCIAL: "SOCIAL",
    },
    ServiceArray:
    [
      {
        "text": "Osobná preprava",
        "value": "PERSONAL",
      }, {
        "text": "Limuzínová preprava",
        "value": "LIMO",
      }, {
        "text": "Nákladná preprava",
        "value": "CARGO",
      }, {
        "text": "Drinktaxi služba",
        "value": "DRINK",
      }, {
        "text": "Preprava imobilných osôb",
        "value": "IMMOBILE",
      }, {
        "text": "Kuriérska služba",
        "value": "COURIER",
      }, {
        "text": "Doručovacia služba",
        "value": "DELIVERY",
      }, {
        "text": "Letiskové taxi",
        "value": "AIRPORT",
      }, {
        "text": "Sociálne taxi",
        "value": "SOCIAL",
      },
    ],
  },
};