import Vue from "vue";
import VueI18n from "vue-i18n";

import store from "./store";

import sk from "./locales/sk";
import en from "./locales/en";
import cs from "./locales/cs";


Vue.use(VueI18n);

// function loadLocaleMessages() {
//   const locales = require.context(
//     "./locales",
//     true,
//     /[A-Za-z0-9-_,\s]+\.json$/i
//   );

//   const messages = {};
//   locales.keys().forEach((key) => {

//     const matched = key.match(/([A-Za-z0-9-_]+)\./i);
//     if (matched && matched.length > 1) {
//       const locale = matched[1];
//       messages[locale] = locales(key);
//     }
//   });
//   console.log(xxx)
//   return messages;
// }

const messages = {
  sk: {
    ...sk,
  },
  en: {
    ...en,
  },
  cs: {
    ...cs,
  },
}

export default new VueI18n({
  locale: store.state.appLocale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "sk",
  messages: messages,
});
