<template>
  <v-main>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-card :loading="loading">
            <v-toolbar color="secondary" dark flat>
              <v-toolbar-title>{{ $t("Login.page_title") }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon>fa-key</v-icon>
            </v-toolbar>
            <v-card-text>
              <v-form ref="form">
                <v-text-field
                  v-model="email"
                  autocomplete="username"
                  :label="$t('Login.email')"
                  name="email"
                  prepend-icon="fa-user"
                  type="text"
                  required
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                />

                <v-text-field
                  v-model="password"
                  autocomplete="current-password"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  id="password"
                  :label="$t('Login.password')"
                  name="password"
                  prepend-icon="fa-lock"
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"
                  @keydown.enter="login()"
                  required
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="success" @click="login">
                {{ $t("Login.btnLogin") }}</v-btn
              >
            </v-card-actions>
            <v-row no-gutters class="justify-center">
              <v-btn
                color="info"
                @click.stop="
                  showForgotPasswordDialog = true;
                  if ($refs.formForgotPassword) {
                    $refs.formForgotPassword.resetValidation();
                  }
                  forgotEmail = '';
                "
                style="text-transform: none !important"
                plain
              >
                {{ $t("Login.btnForgottenPassword") }}</v-btn
              >
              <!-- button to show order list -->
              <v-btn
                :color="btnCheckConnectivityColor"
                @click.stop="checkConnectivity()"
                style="text-transform: none !important"
                plain
                v-show="showCheckConnectivityButton"
              >
                {{ $t("Login.btnCheckConnectivity") }}</v-btn
              >
              <!-- button to show order list -->
              <v-btn
                :color="btnOrderListButtonColor"
                @click.stop="
                  loadOrderList();
                  showOrderList = true;
                "
                style="text-transform: none !important"
                plain
                v-show="showOrderListButton"
              >
                {{ $t("Login.btnOrderList") }}</v-btn
              >
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" dense>
        <v-col cols="12" sm="8" md="4">
          <!-- button to open google play application "Driver" https://play.google.com/store/apps/details?id=com.taxximo.driver2 -->
          <v-btn
            color="warning"
            @click="openGooglePlayPageDriverApp()"
            style="text-transform: none !important"
            plain
            ><v-icon left>mdi-taxi</v-icon>
            {{ $t("Login.btnDriverApp") }}
            <v-icon right>mdi-cellphone-arrow-down-variant</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" dense>
        <v-col cols="12" sm="8" md="4">
          <v-btn
            color="info"
            @click="openGooglePlayPageDriverAppBeta()"
            style="text-transform: none !important"
            plain
            ><v-icon left>mdi-taxi</v-icon>
            {{ $t("Login.btnDriverAppBeta") }}
            <v-icon right>mdi-flask-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" dense>
        <v-col cols="12" sm="8" md="4">
          <v-btn
            :color="!switchToSimpleLogin ? 'grey' : 'green'"
            @click="switchToSimpleLogin = !switchToSimpleLogin"
            style="text-transform: none !important"
            plain
          >
            <v-icon
              left
              v-if="!switchToSimpleLogin"
              color="grey"
              title="Turn ON extended login"
              >mdi-key-remove</v-icon
            >
            <v-icon
              left
              v-if="switchToSimpleLogin"
              color="green"
              title="Turn OFF extended login"
              >mdi-key</v-icon
            >
            {{ $t("Login.btnSimpleLogin") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      transition="dialog-bottom-transition"
      max-width="400"
      v-model="showForgotPasswordDialog"
    >
      <v-toolbar color="secondary" dark flat>
        <v-toolbar-title>{{ $t("Login.dialogTitle") }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon>fa-tired</v-icon>
      </v-toolbar>
      <v-card>
        <v-form ref="formForgotPassword">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="forgotEmail"
                    :label="$t('Login.email')"
                    name="email"
                    prepend-icon="fa-at"
                    type="text"
                    required
                    :rules="[
                      (v) =>
                        !v ||
                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                        $t('Console.msgWrongFormatEmail'),
                    ]"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              text
              @click.stop="showForgotPasswordDialog = false"
            >
              {{ $t("btnClose") }}</v-btn
            >
            <v-btn color="success" text @click.stop="submitForgotPassword()">
              {{ $t("btnSubmit") }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!--    Fullscreen dialog with list of orders from "orderList" with importatnt data only -->
    <v-dialog v-model="showOrderList" fullscreen hide-overlay>
      <v-card>
        <v-toolbar color="secondary" dark flat>
          <v-toolbar-title>{{
            $t("Login.dialogOrderListTitle")
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="showOrderList = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-data-table
            :headers="gridHeaders"
            :items="orderList"
            :items-per-page="25"
            class="elevation-1"
            :footer-props="{
              'items-per-page-options': [5, 10, 25, -1],
            }"
          >
            <template v-slot:[`item.id`]="{ item }">
              <span>
                {{ item.id.slice(0, 8) }}
              </span>
            </template>
            <template v-slot:[`item.waypointList[0].type`]="{ item }">
              <span>
                {{ item.waypointList[0].type.slice(0, 5) }}
              </span>
            </template>
            <template v-slot:[`item.waypointList[1].type`]="{ item }">
              <span>
                {{ item.waypointList[1].type.slice(0, 5) }}
              </span>
            </template>
            <template v-slot:[`item.waypointList[0].address`]="{ item }">
              <span>
                {{ item.waypointList[0].address.slice(0, 40) }}
              </span>
            </template>
            <template v-slot:[`item.waypointList[1].address`]="{ item }">
              <span>
                {{ item.waypointList[1].address.slice(0, 40) }}
              </span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                :color="
                  item.status === 'PENDING'
                    ? 'green'
                    : item.status === 'PLANNED'
                    ? 'purple accent-2'
                    : ''
                "
                label
                small
              >
                {{ $t("orderStatus." + item.status) }}
              </v-chip>
            </template>

            <template v-slot:[`item.type`]="{ item }">
              <v-chip dark label small>
                {{ $t("orderType." + item.type) }}
              </v-chip>
            </template>

            <template v-slot:[`item.paymentType`]="{ item }">
              <v-chip dark label small>
                {{ $t("paymentType." + item.paymentType) }}
              </v-chip>
            </template>

            <template v-slot:[`item.source`]="{ item }">
              <v-chip :color="SourceColor[item.source]" dark label small>
                {{ $t("source." + item.source) }}
              </v-chip>
            </template>

            <template v-slot:[`item.createdAt`]="{ item }">
              {{
                item.createdAt
                  ? $options.filters.formatDateLong(item.createdAt)
                  : "-"
              }}
            </template>
            <template v-slot:[`item.requestedTime`]="{ item }">
              {{
                item.requestedTime
                  ? $options.filters.formatDateLong(item.requestedTime)
                  : "-"
              }}
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="showOrderList = false">
            {{ $t("btnClose") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>

    <!-- Snackbar with button to redirect to https -->
    <v-snackbar
      v-model="snackbarHttps.show"
      :timeout="snackbarHttps.timeout"
      left
      value="true"
      :color="snackbarHttps.color"
    >
      {{ snackbarHttps.message }}
      <v-btn
        @click.stop="redirectActualUrlToHttps()"
        color="error"
        class="ma-2 white--text"
      >
        <v-icon right dark class="mr-2">mdi-directions</v-icon
        >{{ $t("btnRedirectHttps") }}
      </v-btn>
    </v-snackbar>
  </v-main>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import axios from "axios";
import * as moment from "moment/moment";

export default {
  data: () => ({
    moment: moment,
    versionDispatch: process.env.VUE_APP_VERSION,
    showForgotPasswordDialog: false,
    forgotEmail: "",
    debugParameters: {
      debug: "none",
    },
    debugInfo: "",
    switchToSimpleLogin: false,

    email: "",
    password: "",
    loading: false,
    show1: false,

    showCheckConnectivityButton: false,
    btnCheckConnectivityColor: "info",
    showOrderList: false,
    showOrderListButton: false,
    btnOrderListButtonColor: "info",
    orderList: [],
    gridHeaders: [
      {
        text: "ID",
        align: "start",
        value: "id",
      },
      { text: "Stav", value: "status" },
      { text: "Typ", value: "type" },
      { text: "Čas vytvorenia", value: "createdAt" },
      { text: "Požadovaný čas", value: "requestedTime" },
      {
        text: "Typ1",
        value: "waypointList[0].type",
      },
      {
        text: "Adresa1",
        value: "waypointList[0].address",
      },
      {
        text: "Typ2",
        value: "waypointList[1].type",
      },
      {
        text: "Adresa2",
        value: "waypointList[1].address",
      },
      {
        text: "Meno vodiča",
        value: "driver.firstName",
      },
      {
        text: "Priezvisko vodiča",
        value: "driver.lastName",
      },
      {
        text: "Vozidlo",
        value: "vehicle.internalName",
      },
      {
        text: "Preferovaný vodič",
        value: "preferredDriver.lastName",
      },
      {
        text: "Poznámka",
        value: "note",
        sortable: false,
      },
    ],

    snackbar: {
      show: false,
      message: "OK",
      color: "success",
      timeout: 2000,
    },
    snackbarHttps: {
      show: false,
      message: "Change to https protocol",
      color: "warning",
      timeout: 10000,
    },
  }),
  mounted() {
    //Mostik medzi plain javascript funkciou a vue metodov
    //window.markLoadedGoogleMapsApi = this.markLoadedGoogleMapsApi;
    this.debugInfo = this.getDebugInfo();
    this.recommendBrowser();
    this.httpsProtocolCheck();
  },
  methods: {
    //initMap() {
    //console.log("initMap");
    //},
    login() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        this.loading = true;
        firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password)
          .then((userCredential) => {
            //after login remember users firebase data when page is refreshed
            //localStorage.setItem("firebaseData", JSON.stringify(userCredential)); //vymazat ak nechceme aby sa pri refreshi stranky zapamatoval user
            if (this.switchToSimpleLogin) {
              this.getInfoFromBeSimple();
            } else {
              this.getInfoFromBe();
            }
          })
          .catch((err) => {
            console.log("login catch");
            console.log(err);
            alert(err);
            this.loading = false;
            this.snackbar.show = "true";
            this.snackbar.message = this.$t("Login.msgWrongCredentials");
            this.snackbar.color = "error";
            this.snackbar.timeout = 5000;
            this.btnCheckConnectivityColor = "info";
            this.showCheckConnectivityButton = true;
            this.switchToSimpleLogin = true;
          });
      }
    },
    //alternative - simple request
    async getInfoFromBeSimple1() {
      try {
        const token = await firebase.auth().currentUser.getIdToken(false);
        const response = await fetch(
          process.env.VUE_APP_API_BASE_URL +
            "/dispatching/dispatcher/user/info",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              debug: this.debugParameters,
            },
          }
        );
        const responseData = await response.json();
        console.log(responseData);
        this.$store.commit("setUser", responseData);
        localStorage.setItem("fetchRequests", JSON.stringify(true));
        this.getModuleSettingsSimple();
      } catch (error) {
        console.error(error);
        this.loading = false;
        this.snackbar.show = "true";
        this.snackbar.message = this.$t("Login.msgWrongCredentials2");
        this.snackbar.color = "error";
        this.snackbar.timeout = 5000;
        this.btnCheckConnectivityColor = "error";
        this.showCheckConnectivityButton = true;
      }
    },
    getInfoFromBeSimple() {
      const url = `/dispatching/dispatcher/user/info`;
      const options = {
        headers: {
          // Nemusíte získavať token, pretože už je nastavený v main.js
        },
        method: "GET",
      };
      this.$fetchRequest(url, options)
        .then((response) => {
          if (!response.ok) {
            // Ak server vráti chybový status, môžete spracovať chybu tu
            throw new Error(
              `Network response was not ok: ${response.statusText}`
            );
          }
          return response.json();
        })
        .then((responseData) => {
          console.log("fetchRequest responseData");
          console.log(responseData);
          this.$store.commit("setUser", responseData);
          localStorage.setItem("fetchRequests", JSON.stringify(true));
          this.getModuleSettingsSimple();
        })
        .catch((error) => {
          // Spracovanie chyby
          console.error(error);
          this.loading = false;
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("Login.msgWrongCredentials2");
          this.snackbar.color = "error";
          this.snackbar.timeout = 5000;
          this.btnCheckConnectivityColor = "error";
          this.showCheckConnectivityButton = true;
        });
    },
    /*async getInfoFromBe4() {
      let response = () => {
        return new Promise(function (resolve, reject) {
          fetch(
            process.env.VUE_APP_API_BASE_URL +
              `/dispatching/dispatcher/user/info`
            //, {params: this.debugParameters,}
          ).then((response) => {
            resolve(response);
          });
        });
      };
      let responseData = await response();
      console.log(responseData.data);
      this.$store.commit("setUser", response.data);
      this.getModuleSettings();
    },*/

    /*async getInfoFromBe5() {
      try {
        let response = await axios.get(
          process.env.VUE_APP_API_BASE_URL + "/dispatching/dispatcher/user/info"
          //, {params: this.debugParameters}
        );
        console.log(response.data);
        this.$store.commit("setUser", response.data);
        this.getModuleSettings();
      } catch (error) {
        console.error(error);
      }
    },*/

    /*async getInfoFromBe3() {
      try {
        let response = await new Promise((resolve, reject) => {
          axios
            .get(
              process.env.VUE_APP_API_BASE_URL +
                "/dispatching/dispatcher/user/info"
              //, {params: this.debugParameters}
            )
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });

        console.log(response.data);
        this.$store.commit("setUser", response.data);
        this.getModuleSettings();
      } catch (error) {
        console.error(error);
      }
    },*/

    //Standardny request
    getInfoFromBe() {
      this.debugParameters.debug = btoa(
        unescape(encodeURIComponent(this.debugInfo))
      );
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/dispatcher/user/info`,
          {
            params: this.debugParameters,
          }
        )
        .then((response) => {
          this.loading = false;
          if (response.data) {
            this.$store.commit("setUser", response.data);
            this.getModuleSettings();
            this.switchToSimpleLogin = false;
            localStorage.setItem("fetchRequests", JSON.stringify(false));
          } else {
            this.snackbar.show = "true";
            this.snackbar.message = this.$t("Login.msgWrongCredentials2");
            this.snackbar.color = "error";
            this.snackbar.timeout = 5000;
            this.btnCheckConnectivityColor = "error";
            this.showCheckConnectivityButton = true;
            this.switchToSimpleLogin = true;
          }
        })
        .catch((e) => {
          console.log("getInfoFromBe catch");
          console.log(e);
          this.$store.commit("setUser", null);
          firebase
            .auth()
            .signOut()
            .then(() => {});
          this.loading = false;
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("Login.msgWrongCredentials2");
          this.snackbar.color = "error";
          this.snackbar.timeout = 5000;
          this.btnCheckConnectivityColor = "error";
          this.showCheckConnectivityButton = true;
          this.switchToSimpleLogin = true;
        });
    },

    //Standard request
    // get module settings from /dispatching/taxiservice/module
    getModuleSettings() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL + `/dispatching/taxiservice/module`
        )
        .then((response) => {
          if (response.data) {
            this.$store.commit("setModule", response.data);
          } else {
            this.snackbar.show = true;
            this.snackbar.message = this.$t("Login.msgModuleError");
            this.snackbar.color = "error";
            this.snackbar.timeout = 5000;
            alert(this.$t("Login.msgModuleError"));
          }
        })
        .catch((e) => {
          console.log("getModuleSettings catch");
          console.log(e);
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("Login.msgModuleError");
          this.snackbar.color = "error";
          this.snackbar.timeout = 5000;
          //Zobrazenie alertu, lebo snakbar sa pri prechod na inu stranku rychlo strati
          alert(this.$t("Login.msgModuleError"));
        })
        .finally(() => {
          this.$router.push(
            this.$route.query.redirect || { name: "dashboard" }
          );
        });
    },

    //alternative - simple request
    async getModuleSettingsSimple1() {
      try {
        const token = await firebase.auth().currentUser.getIdToken(false);
        const response = await fetch(
          process.env.VUE_APP_API_BASE_URL + `/dispatching/taxiservice/module`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const responseData = await response.json();
        if (responseData) {
          this.$store.commit("setModule", responseData);
          //console.log("login-module settings", responseData);
          this.$router.push(
            this.$route.query.redirect || { name: "dashboard" }
          );
        } else {
          this.snackbar.show = true;
          this.snackbar.message = this.$t("Login.msgModuleError");
          this.snackbar.color = "error";
          this.snackbar.timeout = 5000;
          //Zobrazenie alertu, lebo snakbar sa pri prechod na inu stranku rychlo strati
          alert(this.$t("Login.msgModuleError"));
        }
      } catch (error) {
        console.error(error);
      }
    },
    getModuleSettingsSimple() {
      const url = `/dispatching/taxiservice/module`;
      const options = {
        headers: {
          // Nemusíte získavať token, pretože už je nastavený v main.js
        },
      };
      this.$fetchRequest(url, options)
        .then((response) => {
          if (!response.ok) {
            // Ak server vráti chybový status, môžete spracovať chybu tu
            throw new Error(
              `Network response was not ok: ${response.statusText}`
            );
          }
          return response.json();
        })
        .then((responseData) => {
          if (responseData) {
            this.$store.commit("setModule", responseData);
            //console.log("login-module settings", responseData);
            this.$router.push(
              this.$route.query.redirect || { name: "dashboard" }
            );
          } else {
            this.snackbar.show = true;
            this.snackbar.message = this.$t("Login.msgModuleError");
            this.snackbar.color = "error";
            this.snackbar.timeout = 5000;
            //Zobrazenie alertu, lebo snakbar sa pri prechod na inu stranku rychlo strati
            alert(this.$t("Login.msgModuleError"));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    submitForgotPassword() {
      const isValid = this.$refs.formForgotPassword.validate();
      if (isValid) {
        this.loading = true;
        firebase
          .auth()
          .sendPasswordResetEmail(this.forgotEmail)
          .then((data) => {
            this.showForgotPasswordDialog = false;
            this.loading = false;
            this.snackbar.show = "true";
            this.snackbar.message = this.$t("Login.msgResetSuccess");
            this.snackbar.color = "success";
            this.snackbar.timeout = 2000;
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            this.snackbar.show = "true";
            this.snackbar.message = err.message;
            this.snackbar.color = "error";
            this.snackbar.timeout = 5000;
          });
      }
    },

    getDebugInfo() {
      const currentDate = new Date();
      var windowLocationHref = window.location.href;

      let debugInfo =
        "URL: " +
        windowLocationHref +
        " verD:" +
        this.versionDispatch +
        " OS: " +
        this.detectOs() +
        " Brw: " +
        this.detectBrowser() +
        " Coo: " +
        navigator.cookieEnabled +
        " Lng: " +
        navigator.language +
        " Plf: " +
        navigator.platform +
        " Head: " +
        navigator.userAgent +
        " TmZ: " +
        new Date() +
        " " +
        Intl.DateTimeFormat().resolvedOptions().timeZone +
        " " +
        currentDate.getTimezoneOffset() +
        " Screen: " +
        this.detectScreen();
      return debugInfo;
    },

    recommendBrowser() {
      //ak je prehliadac iny ako Chrome, pridat snackbar s informaciou, ze odporucany prehliadač je chrome
      let browserName = this.detectBrowser();
      if (browserName == "chrome") {
        return true;
      } else if (browserName == "firefox" || browserName == "safari") {
        this.snackbar.show = "true";
        this.snackbar.message = this.$t("Login.msgRecommendedChrome");
        this.snackbar.color = "info";
        this.snackbar.timeout = 5000;
      } else {
        this.snackbar.show = "true";
        this.snackbar.message = this.$t(
          "Login.msgRecommendedChromeUnsupported"
        );
        this.snackbar.color = "warning";
        this.snackbar.timeout = 7000;
      }
    },

    detectBrowser() {
      let userAgent = navigator.userAgent;
      let browserName;

      if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = "chrome";
      } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = "firefox";
      } else if (userAgent.match(/safari/i)) {
        browserName = "safari";
      } else if (
        userAgent.match(/opr\//i) ||
        userAgent.indexOf("trident") != -1
      ) {
        browserName = "opera";
      } else if (userAgent.match(/edg/i) || userAgent.indexOf("Edge") != -1) {
        browserName = "edge";
      } else if (
        userAgent.indexOf("MSIE") != -1 ||
        userAgent.indexOf("trident") != -1 ||
        !!document.documentMode == true
      ) {
        browserName = "IE";
      } else {
        browserName = "No browser detection";
      }
      return browserName;
    },

    detectOs() {
      if (navigator.platform) {
        var userAgent = window.navigator.userAgent,
          platform = window.navigator.platform,
          macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
          windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
          iosPlatforms = ["iPhone", "iPad", "iPod"],
          os = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
          os = "Mac OS";
        } else if (iosPlatforms.indexOf(platform) !== -1) {
          os = "iOS";
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
          os = "Windows";
        } else if (/Android/.test(userAgent)) {
          os = "Android";
        } else if (!os && /Linux/.test(platform)) {
          os = "Linux";
        }

        if (os) {
          return os;
        }
      }

      // Alternativní metoda detekce operačního systému
      var userAgent = window.navigator.userAgent,
        os = null;

      if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent)) {
        os = "Mac OS";
      } else if (/iPhone|iPad|iPod/.test(userAgent)) {
        os = "iOS";
      } else if (/Win32|Win64|Windows|WinCE/.test(userAgent)) {
        os = "Windows";
      } else if (/Android/.test(userAgent)) {
        os = "Android";
      } else if (/Linux/.test(userAgent)) {
        os = "Linux";
      }
      return os;
    },

    detectScreen() {
      let isMobile = window.matchMedia(
        "only screen and (max-width: 760px)"
      ).matches;
      var deviceType;
      if (isMobile) {
        deviceType = "mobile ";
      } else {
        deviceType = "";
      }
      if (window) {
        if (window.screen) {
          var infoScreenResolution =
            window.screen.width + "x" + window.screen.height;
          var infoAvailableScreenspace =
            window.screen.availWidth + "x" + window.screen.availHeight;
        }
        var infoBrowserWindowSize =
          window.innerWidth + "x" + window.innerHeight;
        var infoDevicePixelRatio = window.devicePixelRatio;
        var colorDepth = screen.colorDepth;
      }
      return (
        deviceType +
        "" +
        infoScreenResolution +
        " " +
        infoAvailableScreenspace +
        " " +
        infoBrowserWindowSize +
        " " +
        infoDevicePixelRatio +
        " " +
        colorDepth
      );
    },
    loadOrderList() {
      var savedArray = JSON.parse(localStorage.getItem("orderList"));
      //nezobrazovat zbytocne preregistrovane objednavky
      savedArray = savedArray.filter((item) => item.status !== "PREREGISTERED");
      if (savedArray) {
        if (savedArray.length == 0) return;
        this.orderList = savedArray;
        this.snackbar.show = "true";
        this.snackbar.color = "warning";
        this.snackbar.timeout = 10000;
        this.snackbar.message = this.$t("Login.msgOrderList");
      }
    },
    checkConnectivity() {
      console.log("checkConnectivity");
      //Overenie, funguje request na server na obrazok
      fetch(process.env.VUE_APP_API_BASE_URL + "/favicon.ico", {
        mode: "no-cors",
      })
        .then((response) => {
          this.showOrderListButton = false;
          if (response.status === 404) {
            // súbor neexistuje
            console.log("Súbor favicon.ico neexistuje.");
            //Pripojenie na server zlyhalo.
            this.snackbar.message = this.$t("Login.msgConnectivity404");
            this.btnOrderListButtonColor = "error";
            this.showOrderListButton = true;
          } else if (
            response.headers.get("content-type") !==
            "image/vnd.microsoft.icon; charset=UTF-8"
          ) {
            // súbor existuje, ale nie je typu "image/x-icon"
            console.log(
              'Súbor favicon.ico existuje, ale nie je typu "image/x-icon".'
            );
            this.btnOrderListButtonColor = "info";
            this.showOrderListButton = true;
            //Pripojenie na server je pravdepodobne OK.
            this.snackbar.message = this.$t("Login.msgConnectivity302");
          } else {
            // súbor existuje a je typu "image/x-icon"
            console.log('Súbor favicon.ico existuje a je typu "image/x-icon".');
            this.btnOrderListButtonColor = "info";
            //Pripojenie na server je OK.
            this.snackbar.message = this.$t("Login.msgConnectivity200");
          }
          this.snackbar.show = "true";
          this.snackbar.color = "success";
          this.snackbar.timeout = 10000;
        })
        .catch((e) => {
          this.btnOrderListButtonColor = "error";
          this.showOrderListButton = true;
          console.error("UNAVAILABLE TIME CHECK " + e);
          if (!navigator.onLine) {
            //Zariadenie je offline, nepripojené na wifi alebo mobilné dáta
            this.snackbar.message = this.$t("Login.msgConnectivityOffline");
          } else {
            // Skontrolujeme, či je možné pripojiť sa na iné servery
            fetch("https://www.google.com/images/phd/px.gif", {
              mode: "no-cors",
            })
              .then(() => {
                // Server v datacentre je nedostupný
                this.snackbar.message = this.$t(
                  "Login.msgConnectivityServerDown"
                );
              })
              .catch(() => {
                // Výpadok poskytovateľa internetových služieb, nepodarilo sa pingnúť ani google
                this.snackbar.message = this.$t(
                  "Login.msgConnectivityNoInternet"
                );
              });
          }
          this.snackbar.show = "true";
          this.snackbar.color = "error";
          this.snackbar.timeout = 10000;
        });
    },
    /*loadGoogleMapsApi() {
      const apiKey = "AIzaSyAdG-Vz3YTYE4F5MTALr6-rU1XbQhD-9Ig";
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places,visualization&callback=markLoadedGoogleMapsApi`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
      //this.$emit("googleMapsLoaded");
      console.log("Login.vue - load - google maps loaded");
    },*/
    /*markLoadedGoogleMapsApi() {
      this.$emit("googleMapsLoaded");
      console.log("Main.vue - markLoadedGoogleMapsApi - google maps loaded");
      this.$router.push(this.$route.query.redirect || { name: "dashboard" });
    },*/
    httpsProtocolCheck() {
      //localhost ignorujeme SSL, pri beta upozornime a pri produkcii (dis) priamo  presmerujeme na https
      if (location.protocol !== "https:") {
        //check if page is localhost or 127.0.0.1. If no, redirect to https
        if (
          location.hostname !== "localhost" &&
          location.hostname !== "127.0.0.1"
        ) {
          //if beta.taxxomo.com, redirect to https
          if (location.hostname === "dis.taxximo.com") {
            this.redirectActualUrlToHttps();
          } else {
            this.snackbarHttps.show = "true";
            this.snackbarHttps.color = "warning";
            this.snackbarHttps.timeout = 10000;
            this.snackbarHttps.message = this.$t("msgHttps");
          }
        }
      }
    },
    redirectActualUrlToHttps() {
      if (location.protocol !== "https:") {
        location.href =
          "https:" +
          window.location.href.substring(window.location.protocol.length);
      }
    },
    openGooglePlayPageDriverApp() {
      const packageName = "com.taxximo.driver2";
      const url = `https://play.google.com/store/apps/details?id=${packageName}`;
      window.open(url, "_blank");
    },
    //Otovit link na zaradenie sa do beta programu
    openGooglePlayPageDriverAppBeta() {
      const packageName = "com.taxximo.driver2";
      const url = `https://play.google.com/apps/testing/${packageName}`;
      window.open(url, "_blank");
    },
  },
};
</script>
