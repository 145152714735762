import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";


const baseApiUrl = process.env.VUE_APP_API_BASE_URL;
/* let authToken = null; */
// Funkcia pre úpravu Fetch požiadavky
const fetchRequest = async (url, options) => {
  // Priraďte plnu URL priamo tu
  url = `${baseApiUrl}${url}`;
  /*   if (!authToken) {
      authToken = await firebase.auth().currentUser.getIdToken(false);
    } */
  options.headers = {
    ...options.headers,
    /*  Authorization: `Bearer ${authToken}`, */
    Authorization: `Bearer ` + await firebase.auth().currentUser.getIdToken(false),
    Accept: '*/*',
  };
  return fetch(url, options);
};

export default fetchRequest;