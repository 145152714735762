export const Role = Object.freeze({
  ADMIN: "ADMIN", //taxximo
  SUPPORT: "SUPPORT", //taxximo
  OWNER: "OWNER", //taxisluzba - admin / majitel
  MANAGER: "MANAGER", //taxisluzba
  MAINTAINER: "MAINTAINER", //udrzbar vozidiel
  DISPATCHER: "DISPATCHER" //taxisluzba
});

export const RoleForOwner = Object.freeze({
  MANAGER: "MANAGER", //taxisluzba
  MAINTAINER: "MAINTAINER", //udrzbar vozidiel
  DISPATCHER: "DISPATCHER" //taxisluzba
});
